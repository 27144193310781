var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "shift-card",
      class: { approved: _vm.item.approved_employee_id },
    },
    [
      _c(
        "div",
        { staticClass: "shift-card__main", on: { click: _vm.data.click } },
        [
          _c("div", {
            staticClass: "shift-card__main_card mb8",
            domProps: {
              textContent: _vm._s(_vm.branchName(_vm.item.branch_id)),
            },
          }),
          _c("div", {
            staticClass: "shift-card__main_card mb8",
            domProps: { textContent: _vm._s(_vm.item.position_name) },
          }),
          _c("div", {
            staticClass: "shift-card__main_card mb8",
            domProps: { textContent: _vm._s(_vm.item.employee_name) },
          }),
          _vm.restaurantCurrencyInfo === null
            ? _c("div", {
                staticClass: "shift-card__main_card mb8",
                domProps: {
                  textContent: _vm._s(
                    "₪" + _vm.item.hourly_cost / 100 + _vm.$t(" per hour")
                  ),
                },
              })
            : _vm._e(),
          _vm.restaurantCurrencyInfo !== null
            ? _c("div", {
                staticClass: "shift-card__main_card mb8",
                domProps: {
                  textContent: _vm._s(
                    _vm.restaurantCurrencySymbol +
                      _vm.item.hourly_cost / 100 +
                      _vm.$t(" per hour")
                  ),
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "item-card__main_card" }),
          _c("div"),
          _c(
            "div",
            {
              staticClass: "item-card__main_name",
              style: { "margin-bottom": "10px" },
            },
            [
              _c("div", {
                staticClass: "grey",
                domProps: { textContent: _vm._s(_vm.$t("Start")) },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.filters.date(_vm.item.actual_start, "DD/MM/YYYY")
                  ),
                },
              }),
              _c("div", {
                domProps: {
                  textContent: _vm._s(
                    _vm.filters.date(_vm.item.actual_start, "HH:mm")
                  ),
                },
              }),
            ]
          ),
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("End")) },
            }),
            _c("div", {
              domProps: {
                textContent: _vm._s(
                  _vm.filters.date(_vm.item.actual_end, "DD/MM/YYYY")
                ),
              },
            }),
            _vm.item.original_end
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.filters.date(_vm.item.actual_end, "HH:mm")
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Sum hours")) },
            }),
            _c("div", { domProps: { textContent: _vm._s(_vm.sumHours) } }),
          ]),
          _c("div", { staticClass: "item-card__main_name" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Travels")) },
            }),
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  domProps: { textContent: _vm._s("₪" + _vm.item.travel_cost) },
                })
              : _vm._e(),
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.item.travel_cost
                    ),
                  },
                })
              : _vm._e(),
          ]),
          _c("div"),
          _c("div", { staticClass: "item-card__main_name mt16" }, [
            _c("div", {
              staticClass: "grey",
              domProps: { textContent: _vm._s(_vm.$t("Cost")) },
            }),
            _vm.restaurantCurrencyInfo == null
              ? _c("div", {
                  domProps: { textContent: _vm._s("₪" + _vm.item.cost) },
                })
              : _vm._e(),
            _vm.restaurantCurrencyInfo !== null
              ? _c("div", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.restaurantCurrencySymbol + _vm.item.cost
                    ),
                  },
                })
              : _vm._e(),
          ]),
        ]
      ),
      _c("div", { staticClass: "shift-card__footer" }, [
        _c("div", { staticClass: "buttons-shift" }, [
          _c("button", {
            staticClass: "btn btn_small",
            staticStyle: { "align-self": "center" },
            domProps: { textContent: _vm._s(_vm.$t("Update salary")) },
            on: { click: _vm.data.updateSalary },
          }),
          _vm.data.choosePosition
            ? _c("button", {
                staticClass: "btn btn_small",
                domProps: { textContent: _vm._s(_vm.$t("Change position")) },
                on: { click: _vm.data.choosePosition },
              })
            : _vm._e(),
          !_vm.item.approved_employee_id && _vm.item.actual_end
            ? _c(
                "button",
                { staticClass: "btn-approve", on: { click: _vm.data.approve } },
                [
                  _c("svg-icon", {
                    attrs: { name: "ic-save-btn", width: "50", height: "50" },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }